export type DzBankConfiguration = {
  cardLimit: number;
  cardPrice: number;
  cardType: CardType;
};

export enum CardType {
  VisaCard = 'VisaCard',
  MasterCard = 'MasterCard',
}
