import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ez-already-sent',
  standalone: true,
  imports: [],
  templateUrl: './already-sent.component.html',
  styleUrl: './already-sent.component.scss',
})
export class AlreadySentComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Daten bereits abgesendet');
  }
}
