import { Routes } from '@angular/router';
import { OverviewComponent } from './features/overview/overview.component';
import { PersonalAccessTokenComponent } from './features/personal-access-token/personal-access-token.component';
import { VerificationErrorComponent } from './errors/verification-error/verification-error.component';
import { AlreadySentComponent } from './errors/already-sent/already-sent.component';

export const routes: Routes = [
  {
    path: '',
    component: PersonalAccessTokenComponent,
  },
  {
    path: 'todo',
    component: OverviewComponent,
  },
  {
    path: 'already-sent',
    component: AlreadySentComponent,
  },
  {
    path: 'verification-error',
    component: VerificationErrorComponent,
  },
];
