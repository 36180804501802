import { Component, Input } from '@angular/core';
import { ImageData } from '../../types';

@Component({
  selector: 'ez-header',
  imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input({ required: true }) imageData: ImageData;
}
