@let _organisation = organisation()!;
@let _customData = customData()!;
@let _dzBankConfiguration = dzBankConfiguration()!;
@let _customer = customer()!;
@let _ibanAnonymised = ibanAnonymised()!;
@if (_organisation && _dzBankConfiguration && _customer && _ibanAnonymised) {
  <ez-main-layout [theme]="Themes.NONE">
    <div class="block--centered">
      <ez-stepper></ez-stepper>

      <h1>Zusammenfassung</h1>

      <ez-credit-card-image></ez-credit-card-image>

      <div id="cardInfo" class="block--colored block--left">
        <h2>Kartentyp:</h2>
        <span id="cardType">Kreditkarte Gold ({{ _dzBankConfiguration.cardType }})</span>
        <br />
        <h2>Herausgeber Ihrer Karte:</h2>
        <span id="bankName">{{ _organisation.shortName }} (in Zusammenarbeit mit der DZ BANK)</span>
        <br />
        <h2>Kosten Ihrer Karte:</h2>
        <span id="cardPrice">{{ _dzBankConfiguration.cardPrice | currency: '€' : 'code' : '1.2-2' }} pro Jahr</span>
        @if (
          _customData['cardPriceSecondYear'] || _customData['cardPriceThirdYear'] || _customData['cardPriceFourthYear']
        ) {
          <span>*</span>
          <p class="text--very-small">
            *
            @if (_customData['cardPriceSecondYear']) {
              Ab dem zweiten Jahr beträgt der Preis der Kreditkarte
              {{ _customData['cardPriceSecondYear'] }} €.
            }
            @if (_customData['cardPriceThirdYear']) {
              Ab dem dritten Jahr beträgt der Preis der Kreditkarte
              {{ _customData['cardPriceThirdYear'] }} €.
            }
            @if (_customData['cardPriceFourthYear']) {
              Ab dem vierten Jahr beträgt der Preis der Kreditkarte
              {{ _customData['cardPriceFourthYear'] }} €.
            }
          </p>
        }
        <h2>Name des Empfängers:</h2>
        <span id="customerName">{{ _customer.title }} {{ _customer.firstName }} {{ _customer.lastName }}</span>
        <h2>Lieferadresse Ihrer Kreditkarte:</h2>
        <span id="address"
          >{{ _customer.deliveryAddress.street }},
          {{ _customer.deliveryAddress.postalCode }}
          {{ _customer.deliveryAddress.city }}</span
        >
        <h2>Abrechnungskonto Ihrer Kreditkarte:</h2>
        <span id="iban">{{ _ibanAnonymised | ibanFormatter }}</span>
        <h2>E-Mail-Adresse:</h2>
        <span id="email">{{ _customer.email }}</span>
      </div>

      <div id="acceptancesGiven" class="block--colored block--left">
        <h2>Abgegebene Erklärungen:</h2>
        <ul>
          <li>Ermächtigung zur Erteilung einer Bankauskunft</li>
          <li>Angabe nach § 3 Geldwäschegesetz</li>
          <li>SEPA-Lastschriftmandat</li>
        </ul>
        <p class="text--small">
          Bitte überprüfen Sie die Auftragsdaten auf dieser Seite auf Ihre Korrektheit. Diese Informationen übersenden
          wir Ihnen nach der Beantragung zusätzlich per E-Mail an die hinterlegte E-Mail-Adresse. Bitte beachten Sie Ihr
          gesetzliches Widerrufsrecht. Details finden Sie in den vorvertraglichen Informationen, die Sie bereits per
          E-Mail erhalten haben.
        </p>
        <p class="text--small">
          Mit dem Klicken des Buttons geben Sie ein bindendes Angebot zu den
          <a
            [href]="_organisation.customFields['conditionsLink']"
            target="_blank"
            aria-label="Konditionen und Bedingungen - öffnet in einem neuen Fenster"
            >Konditionen und Bedingungen</a
          >
          ab, die Sie auch bereits per E-Mail erhalten haben.
        </p>
      </div>

      <div id="mailDisclaimer" class="block--colored">
        <p>
          Ihre vorvertraglichen Informationen wurden von <b>info&#64;meineneuekreditkarte.de</b> an Sie versendet.
          Prüfen Sie gegebenenfalls auch Ihren Spam-Ordner.
        </p>
      </div>

      <ez-button
        [loading]="orderCreditCardRequestIsLoading"
        (buttonClick)="onSubmit()"
        class="mobile-padded"
        label="Zahlungspflichtig beantragen"
        maxWidth="450px"
        altText="Mit Klick auf dieses orangene Rechteck bestätigen Sie, dass Sie die Kreditkarte zahlungspflichtig bestellen wollen."
      >
      </ez-button>

      <p class="text--very-small mobile-padded">
        Alle relevanten Unterlagen werden Ihnen nach der Antragserstellung noch einmal per E-Mail bereitgestellt.
      </p>
    </div>
  </ez-main-layout>
}
