<div class="block--flex">
  <ez-checkbox-input
    #checkbox
    [name]="name ? name : controlName"
    [controlName]="controlName"
    [isContentVisible]="false"
    [altText]="altText"
  >
    {{ label }}
  </ez-checkbox-input>
  <ez-expandable [header]="label" headerFontWeight="normal">
    <ng-content select=".preview" class="preview"></ng-content>
    <ng-content></ng-content>
  </ez-expandable>
</div>
@if (checkbox.hasError()) {
  <p class="error-message" role="alert" [attr.aria-label]="label">{{ checkbox.getErrorMessage() }}</p>
}
