<div class="bottom">
  <div class="left top">
    <img
      class="logo"
      [src]="imageData.imgSrc"
      [attr.width]="imageData.imgWidth"
      [attr.height]="imageData.imgHeight"
      alt="Logo der {{ organisationName }}"
    />
  </div>
  <div class="right stack">
    <a
      class="imprint"
      target="_blank"
      rel="noopener noreferrer"
      [href]="imprintUrl"
      aria-label="Impressum - öffnet in einem neuen Fenster"
      >Impressum</a
    >
    <a
      class="dataPrivacy"
      target="_blank"
      rel="noopener noreferrer"
      [href]="dataPrivacyUrl"
      aria-label="Datenschutz - öffnet in einem neuen Fenster"
      >Datenschutz</a
    >
  </div>
</div>
