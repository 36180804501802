<div class="email-input-container" [formGroup]="form">
  <input
    placeholder="E-Mail Adresse eingeben"
    type="email"
    [name]="name"
    [formControlName]="controlName"
    [value]="this.control.value"
    [class.error]="hasError()"
  />
  @if (hasError()) {
    <span class="error-message">{{ getErrorMessage() }}</span>
  }
</div>
