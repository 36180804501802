import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'ez-info-button',
  imports: [FaIconComponent],
  templateUrl: './info-button.component.html',
  styleUrl: './info-button.component.scss',
})
export class InfoButtonComponent {
  @ViewChild('icon') icon: ElementRef<HTMLElement>;
  @Output() clickEvent = new EventEmitter<void>();

  onClick() {
    this.clickEvent.emit();
  }

  protected readonly faCircleInfo = faCircleInfo;
}
