import { Component } from '@angular/core';

@Component({
  selector: 'ez-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  imports: [],
  providers: [],
})
export class OverviewComponent {
  constructor() {}
}
