<!-- TODO: replace hardcoded values? -->
@let _organisation = organisation();
@if (headerConfig) {
  <ez-header [imageData]="headerConfig"></ez-header>
}
<ng-content select="ez-stepper"></ng-content>
<ng-content select="ez-banner"></ng-content>

<div class="page-content">
  <ng-content></ng-content>
</div>

@if (_organisation) {
  <div class="block--centered" id="contactButton">
    <ez-contact
      logoUrl="assets/icons/VR_Logo_RGB.png"
      [companyName]="_organisation.shortName"
      [companyAddress]="{
        street: _organisation.address.street,
        streetNumber: _organisation.address.streetNumber,
        zipCode: _organisation.address.zipCode,
        city: _organisation.address.city,
      }"
      [companyPhone]="_organisation.contactInfo.phone"
      [companyEmail]="_organisation.contactInfo.email"
      [companyWeb]="_organisation.web.homepageUrl"
    >
    </ez-contact>
  </div>
}

<div class="footer">
  <a class="dataPrivacy" target="_blank" rel="noopener noreferrer" [href]="dataProtectionUrl">Datenschutz</a>
  <a class="imprint" target="_blank" rel="noopener noreferrer" [href]="imprintUrl">Impressum</a>
</div>
