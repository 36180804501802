import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageData } from '../../types';

@Component({
  selector: 'ez-footer',
  imports: [ReactiveFormsModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit {
  //background color is determined by --tertiary-background-color variable
  @Input({ required: true }) imprintUrl: string;
  @Input({ required: true }) dataPrivacyUrl: string;
  @Input({ required: true }) imageData: ImageData;
  @Input({ required: true }) organisationName: string;

  ngOnInit(): void {
    if (this.imprintUrl.startsWith('www')) {
      this.imprintUrl = 'https://' + this.imprintUrl;
    }
    if (this.dataPrivacyUrl.startsWith('www')) {
      this.dataPrivacyUrl = 'https://' + this.dataPrivacyUrl;
    }
  }
}
