import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'ez-box-shadow',
  imports: [NgStyle],
  templateUrl: './box-shadow.component.html',
  styleUrl: './box-shadow.component.scss',
})
export class BoxShadowComponent {
  @Input({ required: true }) width: string;
  @Input({ required: true }) height: string;
  @Input({ required: true }) shadowColor: string;
  @Input({ required: true }) shadowBottom: string;
  @Input({ required: true }) shadowLeft: string;
  @Input() shadowWidth: string = '100%';
  @Input() shadowHeight: string = '100%';
}
