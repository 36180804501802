import { Component } from '@angular/core';

/** Banner that is to be displayed at the top of the site, under the logo.
 *
 * Please provide also the following css variable for correct styling:
 * - banner-background-color: sets the background color for the banner
 * - banner-text-primary-color: sets the color for the large main font
 * - banner-text-secondary-color: sets the color for the subtitle below the main font
 */

@Component({
  selector: 'ez-banner',
  imports: [],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
})
export class BannerComponent {}
