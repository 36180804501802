@let _organisation = organisation()!;
@if (_organisation) {
  <ez-main-layout [theme]="Themes.NONE">
    <ez-banner>
      <ez-stepper></ez-stepper>
      <span ngProjectAs="title">Herzlichen Glückwunsch!</span>
    </ez-banner>

    <p class="center">
      <b>
        Sie haben die GoldCard Ihrer {{ _organisation.shortName }} in Zusammenarbeit mit der DZ BANK als Herausgeber der
        Kreditkarte erfolgreich beantragt.
      </b>
    </p>

    <p class="center">
      Die zusammenfassende Bestätigung erhalten Sie von {{ _organisation.emailSenderAddress }} per E-Mail. Prüfen Sie
      gegebenenfalls auch Ihren Spam-Ordner.
    </p>
  </ez-main-layout>
}
