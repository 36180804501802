import { Injectable } from '@angular/core';
import { CanActivate, GuardResult, MaybeAsync, Router } from '@angular/router';
import { AuthenticationStateService } from 'app/shared/services/state/authentication-state.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalAccessTokenGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationStateService: AuthenticationStateService,
  ) {}

  canActivate(): MaybeAsync<GuardResult> {
    if (this.authenticationStateService.personalAccessToken()) {
      return true;
    }

    const storedPersonalAccessToken = sessionStorage.getItem('personalAccessToken');
    if (storedPersonalAccessToken) {
      this.authenticationStateService.setPersonalAccessToken(storedPersonalAccessToken);
      return true;
    }

    return this.router.parseUrl('/');
  }
}
