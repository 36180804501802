import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { parseBackendErrorResponse } from './errors/backend.errors';
import { environment } from '@environment/environment';

/**
 * BackendService is a service class that provides methods for interacting with the backend API.
 */
@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {}

  /**
   * submitPersonalAccessToken is a method that sends a personal access token to the backend API.
   * @param personalAccessToken - External case identifier.
   */
  submitPersonalAccessToken(personalAccessToken: string) {
    return this.http
      .get<unknown>(`${environment.apiURL}/todo/${personalAccessToken}`)
      .pipe(catchError(this.httpErrorHandler.bind(this)));
  }

  /**
   * Handles HTTP errors occurred in backend service.
   *
   * @param response - The HttpErrorResponse that occurred.
   * @returns Observable that emits an Error object with custom error message.
   */
  private httpErrorHandler(response: HttpErrorResponse) {
    this.logger.error(response);
    const backendErrorType = parseBackendErrorResponse(response);
    return throwError(() => new Error(backendErrorType));
  }
}
