@if (currentStepConfig) {
  @for (config of stepsConfigs; track config) {
    <ng-container *ngTemplateOutlet="stepTemplate; context: { $implicit: config }"></ng-container>
  }

  <ng-template #stepTemplate let-config>
    @let currentStep = currentStepConfig.step;
    @let step = config.step;
    @let description = config.description;

    <div class="step" [class.step-current]="step === currentStep" [title]="description">
      @if (step < currentStep) {
        <span class="visually-hidden">Abgeschlossen: </span>
      }
      @if (step === currentStep) {
        <span class="visually-hidden">Aktueller Schritt: </span>
      }
      <span>{{ step }}</span>
      <span class="visually-hidden">{{ description }}</span>
    </div>
  </ng-template>
}
