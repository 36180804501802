import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { BackendErrorType } from '../services/errors/backend.errors';
import { BackendService } from '../services/backend.service';
import { LandingpageConfigurationStateService } from '../services/state/landingpage-configuration-state.service';
import { AuthenticationStateService } from '../services/state/authentication-state.service';
import { LandingpageOrganisationResponseDTO } from '../models/dto/landingpage-organisation-response-dto';

@Injectable({
  providedIn: 'root',
})
/***
 routes either to OverviewComponent, Error page or AccessToken Component, dependig on the validity of the token passed in the url
 ***/
export class CheckForTokenGuard implements CanActivate {
  constructor(
    private backendService: BackendService,
    private router: Router,
    private logger: NGXLogger,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
    private authenticationStateService: AuthenticationStateService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> | UrlTree {
    if (!route.queryParamMap.has('code')) {
      return this.router.parseUrl('/');
    }
    const token = route.queryParamMap.get('code')!;
    return this.isTokenValid(token);
  }

  isTokenValid(token: string): Observable<UrlTree> {
    return new Observable<UrlTree>((observer) => {
      this.backendService.submitPersonalAccessToken(token).subscribe({
        next: (response: LandingpageOrganisationResponseDTO) => {
          this.logger.debug('Request Landingpage Organisation:', {
            requestParam: token,
            response: response,
          });

          this.authenticationStateService.setPersonalAccessToken(token);
          this.landingpageConfigurationStateService.setLandingpageOrganisation(response);

          observer.next(this.router.parseUrl('/auth'));
        },
        error: (error: Error) => {
          if (error.message === BackendErrorType.ALREADY_SENT) {
            observer.next(this.router.parseUrl('already-sent'));
          } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
            observer.next(this.router.parseUrl('verification-error'));
          } else {
            observer.next(this.router.parseUrl('/'));
          }
        },
      });
    });
  }
}
