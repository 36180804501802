import { Component, Input, ViewChild } from '@angular/core';
import { CheckboxInputComponent } from '../checkbox-input/checkbox-input.component';
import { ExpandableComponent } from '../expandable/expandable.component';

@Component({
  selector: 'ez-expandable-checkbox',
  imports: [CheckboxInputComponent, ExpandableComponent],
  templateUrl: './expandable-checkbox.component.html',
  styleUrl: './expandable-checkbox.component.scss',
})
export class ExpandableCheckboxComponent {
  @Input({ required: true }) label: string;
  @Input({ required: true }) controlName: string;
  @Input({ required: true }) altText: string;
  @Input() name: string;
  @ViewChild('checkbox') checkbox: CheckboxInputComponent;
}
