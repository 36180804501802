import { Component } from '@angular/core';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';

@Component({
  selector: 'ez-verification-error',
  imports: [MainLayoutComponent],
  templateUrl: './verification-error.component.html',
  styleUrl: './verification-error.component.scss',
})
export class VerificationErrorComponent {}
