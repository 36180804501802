import { Pipe, PipeTransform } from '@angular/core';
import { friendlyFormatIBAN } from 'ibantools';

/*
 * Format iban
 * Usage:
 *   value | ibanFormatter
 * Example:
 *   {{ 'NL91ABNA0417164300' | ibanFormatter }}
 *   formats to: NL91 ABNA 0417 1643 00
 */
@Pipe({
  name: 'ibanFormatter',
})
export class IbanFormatterPipe implements PipeTransform {
  transform(value: string): string {
    return friendlyFormatIBAN(value)!;
  }
}
