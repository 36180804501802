<div id="accountInfo" class="bordered flex-centered">
  <img id="vbLogo-small" src="assets/icons/VR_Logo_RGB.png" alt="Logo der VR Banken" />
  <div class="flex-centered nameAndIban">
    <div id="name">
      <p class="text--small little-padding">{{ name }}<br /></p>
      <p class="text--small text--bold little-padding">IBAN</p>
    </div>
    <span class="text--small text--bold bottom-row-mobile">{{ iban | ibanFormatter }}</span>
  </div>
</div>
