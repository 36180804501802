@let _assetStackBucketUrl = assetStackBucketUrl()!;
@let _organisation = organisation()!;
@let _dzBankConfiguration = dzBankConfiguration()!;
@if (_organisation.corporateDesign.logos.customLogos['creditCard']) {
  <img
    class="img--mobile-compatible creditCardImg"
    src="{{ _assetStackBucketUrl }}{{ _organisation.corporateDesign.logos.customLogos['creditCard'] }}"
    alt="Bild Ihrer möglichen neuen Kreditkarte Gold"
  />
} @else if (_dzBankConfiguration.cardType === CardType.VisaCard) {
  <img
    class="img--mobile-compatible creditCardImg"
    src="{{ _assetStackBucketUrl }}public/VS_Visa_Gold.png"
    alt="Bild Ihrer möglichen neuen {{ CardType.VisaCard }}"
  />
} @else {
  <img
    class="img--mobile-compatible creditCardImg"
    src="{{ _assetStackBucketUrl }}public/VS_Mastercard_Gold.png"
    alt="Bild Ihrer möglichen neuen {{ CardType.MasterCard }}"
  />
}
