import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'ez-expandable',
  imports: [FaIconComponent],
  templateUrl: './expandable.component.html',
  styleUrl: './expandable.component.scss',
})
export class ExpandableComponent {
  @Input({ required: true }) header: string;
  @Input() headerFontWeight = 'bold';
  @Output() isDetailsOpen = new EventEmitter<boolean>();
  @ViewChild('details') details: ElementRef<HTMLDetailsElement>;
  @ViewChild('summary') summary: ElementRef<HTMLDetailsElement>;

  onToggle() {
    // TODO event direkt als ToggleEvent definieren
    const isDetailsOpen = this.details.nativeElement.open;
    this.isDetailsOpen.emit(isDetailsOpen);
  }
  close() {
    if (this.details.nativeElement.open) {
      this.summary.nativeElement.click();
    }
  }

  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;
}
