import { OverviewComponent } from './features/overview/overview.component';
import { PersonalAccessTokenComponent } from './features/personal-access-token/personal-access-token.component';
import { VerificationErrorComponent } from './errors/verification-error/verification-error.component';
import { AlreadySentComponent } from './errors/already-sent/already-sent.component';
import { AuthenticationComponent } from './features/authentication/authentication.component';
import { VerifyEmailComponent } from './features/verify-email/verify-email.component';
import { RouteWithSteps } from './shared/components/stepper/step-configuration.model';
import { CheckForTokenGuard } from './shared/guards/token-guard';
import { CompletionComponent } from './features/completion/completion.component';
import { SummaryComponent } from './features/summary/summary.component';
import { PersonalAccessTokenGuard } from './shared/guards/personal-access-token-guard/personal-access-token-guard.guard';
import { PasswordGuard } from './shared/guards/password-guard/password.guard';
import { ServerErrorComponent } from './errors/server-error/server-error.component';

export const routes: RouteWithSteps[] = [
  {
    path: '',
    title: 'Zugangscodeabfrage',
    component: PersonalAccessTokenComponent,
  },
  {
    path: 'verify',
    title: 'Zugangscodeabfrage',
    canActivate: [CheckForTokenGuard],
    component: PersonalAccessTokenComponent,
  },
  {
    path: 'auth',
    title: 'Authentifizierung',
    component: AuthenticationComponent,
    canActivate: [PersonalAccessTokenGuard],
    data: { stepConfig: { step: 1, description: 'Authentifizierung' } },
  },
  {
    path: 'overview',
    title: 'Antragsübersicht',
    component: OverviewComponent,
    canActivate: [PersonalAccessTokenGuard, PasswordGuard],
    data: { stepConfig: { step: 2, description: 'Antragsübersicht' } },
  },
  {
    path: 'verify-email',
    title: 'Bestätigung Ihrer E-Mail-Adresse',
    component: VerifyEmailComponent,
    canActivate: [PersonalAccessTokenGuard, PasswordGuard],
    data: { stepConfig: { step: 3, description: 'Bestätigung Ihrer E-Mail-Adresse' } },
  },
  {
    path: 'summary',
    title: 'Zusammenfassung',
    component: SummaryComponent,
    canActivate: [PersonalAccessTokenGuard, PasswordGuard],
    data: { stepConfig: { step: 4, description: 'Zusammenfassung' } },
  },
  {
    path: 'completion',
    title: 'Abschluss',
    component: CompletionComponent,
    canActivate: [PersonalAccessTokenGuard, PasswordGuard],
    data: { stepConfig: { step: 5, description: 'Abschluss' } },
  },
  {
    path: 'already-sent',
    title: 'Daten bereits abgesendet',
    component: AlreadySentComponent,
  },
  {
    path: 'verification-error',
    title: 'Verifizierung nicht möglich',
    component: VerificationErrorComponent,
  },
  {
    path: 'error',
    title: 'Ein Fehler ist aufgetreten',
    component: ServerErrorComponent,
  },
];
