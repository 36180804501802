import { Component, LOCALE_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CorporateDesignService } from './shared/services/corporate-design.service';

registerLocaleData(localeDe);

@Component({
  selector: 'ez-root',
  imports: [RouterOutlet, FontAwesomeModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [{ provide: LOCALE_ID, useValue: 'de-DE' }],
})
export class AppComponent {
  constructor(private corporateDesignService: CorporateDesignService) {} // CorporateDesignService is injected to initiate its effect to update the design
}
