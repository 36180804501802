import { NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouteWithSteps, StepConfiguration } from './step-configuration.model';
import { routes } from 'app/app.routes';

@Component({
  selector: 'ez-stepper',
  imports: [NgTemplateOutlet],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
})
export class StepperComponent {
  public stepsConfigs: StepConfiguration[];
  public currentStepConfig: StepConfiguration | undefined;

  constructor(private route: ActivatedRoute) {
    this.currentStepConfig = (this.route.snapshot as RouteWithSteps).data?.stepConfig;
    this.stepsConfigs = routes
      .filter((route) => route.data && route.data['stepConfig'])
      .map((route) => route.data!['stepConfig'] as StepConfiguration)
      .sort((a, b) => a.step - b.step);
  }
}
