import { HttpErrorResponse } from '@angular/common/http';

export enum BackendErrorType {
  ALREADY_SENT = 'ALREADY_SENT',
  VERIFICATION_ERROR = 'VERIFICATION_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  CLIENT_ERROR = 'CLIENT_ERROR',
}

export function parseBackendErrorResponse(response: HttpErrorResponse): BackendErrorType {
  const errorDetails = response.error?.details || '';
  const errorStatus = response.status || -1;

  if (response.status === 400 && errorDetails.includes('already marked as paid')) {
    return BackendErrorType.ALREADY_SENT;
  } else if (response.status === 400 && errorDetails.includes('already closed')) {
    return BackendErrorType.VERIFICATION_ERROR;
  } else if (errorStatus >= 400 && errorStatus <= 499) {
    return BackendErrorType.CLIENT_ERROR;
  } else if (errorStatus >= 500 && errorStatus <= 599) {
    return BackendErrorType.SERVER_ERROR;
  }

  return BackendErrorType.VERIFICATION_ERROR;
}
