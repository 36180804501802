import { Component, Input, OnInit, Signal } from '@angular/core';
import { ContactComponent, HeaderComponent, ImageData } from 'eazi-library';
import { setTheme, Themes } from '../../../../../themes';
import { Organisation } from 'app/shared/models/organisation.model';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';

@Component({
  selector: 'ez-main-layout',
  imports: [HeaderComponent, ContactComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss',
})
export class MainLayoutComponent implements OnInit {
  @Input() theme: Themes = Themes.WHITELABEL;
  headerConfig: ImageData;
  assetStackBucketUrl: Signal<string | null>;
  organisation: Signal<Organisation | null>;

  constructor(private landingpageConfigurationStateService: LandingpageConfigurationStateService) {}

  ngOnInit(): void {
    this.organisation = this.landingpageConfigurationStateService.organisation;
    this.assetStackBucketUrl = this.landingpageConfigurationStateService.assetStackBucketUrl;

    const mainLogo = this.organisation()?.corporateDesign.logos.mainLogo;
    const mainLogoUrl = this.assetStackBucketUrl() && mainLogo ? this.assetStackBucketUrl() + mainLogo : null;
    this.headerConfig = this.headerConfig ?? {
      imgSrc: mainLogoUrl ?? 'assets/icons/VR_Logo_RGB.png',
      imgWidth: '250px',
      imgHeight: '100px',
    };
    setTheme(this.theme);
  }

  get imprintUrl(): string {
    return (
      this.organisation()?.web.imprintUrl ??
      'https://www.dzbank.de/content/dzbank/de/home/footer/rechtliche-hinweise/impressum.html'
    );
  }

  get dataProtectionUrl(): string {
    return (
      this.organisation()?.web.dataProtectionUrl ??
      'https://www.dzbank.de/content/dzbank/de/home/footer/rechtliche-hinweise/datenschutz.html'
    );
  }
}
