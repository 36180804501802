import { Component, Input } from '@angular/core';
import { IbanFormatterPipe } from '../../pipes/iban-formatter/iban-formatter.pipe';

@Component({
  selector: 'ez-bank-account-info',
  imports: [IbanFormatterPipe],
  templateUrl: './bank-account-info.component.html',
  styleUrl: './bank-account-info.component.scss',
})
export class BankAccountInfoComponent {
  @Input({ required: true }) iban: string;
  @Input({ required: true }) name: string;
}
