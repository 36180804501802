import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'ez-verification-error',
  standalone: true,
  imports: [],
  templateUrl: './verification-error.component.html',
  styleUrl: './verification-error.component.scss',
})
export class VerificationErrorComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Verifizierung nicht möglich');
  }
}
