import { DOCUMENT } from '@angular/common';
import { effect, Inject, Injectable } from '@angular/core';
import { Colors, CorporateDesign, Fonts, Icons } from '../models/organisation.model';
import { LandingpageConfigurationStateService } from './state/landingpage-configuration-state.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateDesignService {
  root: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
  ) {
    this.root = this.document.querySelector(':root')!;
    effect(() => {
      const organisation = this.landingpageConfigurationStateService.organisation();

      if (organisation) {
        this.updateTheme(organisation.corporateDesign);
      }
    });
  }

  getColor(name: string) {
    return this.root.style.getPropertyValue(name);
  }

  private updateTheme(corporateDesign: CorporateDesign) {
    this.updateColors(corporateDesign.colors);
    this.updateIcons(corporateDesign.icons);
    this.updateFonts(corporateDesign.fonts);
  }

  private updateColors(colors: Colors) {
    this.root.style.setProperty('--primary-text-color', colors.primaryTextColor);
    this.root.style.setProperty('--secondary-text-color', colors.secondaryTextColor);
    this.root.style.setProperty('--tertiary-text-color', colors.tertiaryTextColor);
    this.root.style.setProperty('--primary-background-color', colors.primaryBackgroundColor);
    this.root.style.setProperty('--secondary-background', colors.secondaryBackgroundColor);
    this.root.style.setProperty('--tertiary-background-color', colors.tertiaryBackgroundColor);
    this.root.style.setProperty('--active-ui-color', colors.activeUiColor);
    this.root.style.setProperty('--active-ui-text-color', colors.activeUiTextColor);
    this.root.style.setProperty('--inactive-ui-color', colors.inactiveUiColor);
    this.root.style.setProperty('--inactive-ui-text-color', colors.inactiveUiTextColor);
    this.root.style.setProperty('--error-color', colors.errorColor);
  }

  private updateIcons(icons: Icons): void {
    const faviconSvg = this.document.querySelector('#favicon-svg') as HTMLLinkElement;
    const faviconIco = this.document.querySelector('#favicon-ico') as HTMLLinkElement;

    faviconSvg.setAttribute('href', icons.faviconSvg);
    faviconIco.setAttribute('href', icons.faviconIco);
  }

  private updateFonts(fonts: Fonts): void {
    this.root.style.setProperty('--font-family', fonts.primaryFont);
  }
}
