import { Component, LOCALE_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { Title } from '@angular/platform-browser';

registerLocaleData(localeDe);

@Component({
  selector: 'ez-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [{ provide: LOCALE_ID, useValue: 'de-DE' }],
})
export class AppComponent {
  title = this.titleService.getTitle();

  constructor(private titleService: Title) {
    this.titleService.setTitle('TODO');
  }
}
