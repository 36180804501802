import { Component } from '@angular/core';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';

@Component({
  selector: 'ez-server-error',
  imports: [MainLayoutComponent],
  templateUrl: './server-error.component.html',
  styleUrl: './server-error.component.scss',
})
export class ServerErrorComponent {}
