@let _organisation = organisation()!;
@if (_organisation) {
  <ez-main-layout [theme]="Themes.NONE">
    <ez-banner>
      <ez-stepper></ez-stepper>
      <span ngProjectAs="title">Bestätigen Sie Ihre E-Mail-Adresse</span>
    </ez-banner>

    <p style="margin-top: 0">
      Für einen sicheren Vorgang benötigen wir eine Bestätigung Ihrer E-Mail-Adresse. Sie erhalten in Kürze eine E-Mail
      von {{ _organisation.emailSenderAddress }}
      mit dem Verifizierungscode.
    </p>

    <p>
      Sollten Sie keine E-Mail erhalten haben, überprüfen Sie bitte ihren Spam-Ordner, oder Ihre E-Mail-Adresse und
      nutzen Sie den "Zurück"-Button ihres Browsers.
    </p>

    <p>Bitte geben Sie im nachfolgenden Feld Ihren persönlichen Verifizierungscode ein.</p>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <input
        type="text"
        formControlName="emailVerificationCode"
        placeholder="Persönlichen Verifizierungscode eingeben"
      />
      @if (
        emailVerificationCodeControl.invalid &&
        (emailVerificationCodeControl.dirty || emailVerificationCodeControl.touched)
      ) {
        <!-- Error: Code stimmt nicht mit dem Verifizierungscode überein -->
        @if (emailVerificationCodeControl.hasError('required')) {
          <span class="error-message"
            >Fehler: Dies ist ein Pflichtfeld. Bitte geben Sie Ihren persönlichen Verifizierungscode an.</span
          >
        }
        @if (emailVerificationCodeControl.hasError('codeMismatch')) {
          <span class="error-message">Fehler: Code stimmt nicht mit dem Verifizierungscode überein.</span>
        }
      }

      <ez-button
        label="Senden"
        buttonType="submit"
        [disabled]="!(form && form.valid)"
        [loading]="verifyEmailCodeRequestIsLoading"
        altText="Drücken Sie diesen rechteckigen, orangen Button unterhalb des Eingabefeldes zum Senden des persönlichen Verifizierungscodes."
      />
    </form>

    <small>
      Mit dem Klicken des Buttons stellen wir Ihnen die vorvertraglichen Informationen (inkl. Widerrufsbelehrung) per
      E-Mail bereit.
    </small>
  </ez-main-layout>
}
