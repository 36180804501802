import { Component, OnInit, Signal } from '@angular/core';
import { CardType, DzBankConfiguration } from '../../models/dzbank-configuration.model';
import { Organisation } from 'app/shared/models/organisation.model';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';

@Component({
  selector: 'ez-credit-card-image',
  imports: [],
  templateUrl: './credit-card-image.component.html',
  styleUrl: './credit-card-image.component.scss',
})
export class CreditCardImageComponent implements OnInit {
  protected readonly CardType = CardType;
  assetStackBucketUrl: Signal<string | null>;
  organisation: Signal<Organisation | null>;
  dzBankConfiguration: Signal<DzBankConfiguration | null>;

  constructor(private landingpageConfigurationStateService: LandingpageConfigurationStateService) {}

  ngOnInit(): void {
    this.assetStackBucketUrl = this.landingpageConfigurationStateService.assetStackBucketUrl;
    this.organisation = this.landingpageConfigurationStateService.organisation;
    this.dzBankConfiguration = this.landingpageConfigurationStateService.dzBankConfiguration;
  }
}
