import { Component } from '@angular/core';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';

@Component({
  selector: 'ez-already-sent',
  imports: [MainLayoutComponent],
  templateUrl: './already-sent.component.html',
  styleUrl: './already-sent.component.scss',
})
export class AlreadySentComponent {}
