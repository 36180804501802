<div class="ez-box-shadow-container" [ngStyle]="{ width: width, height: height }">
  <div class="ez-box-shadow-icon">
    <ng-content></ng-content>
  </div>
  <div
    class="ez-box-shadow"
    [ngStyle]="{
      bottom: shadowBottom,
      left: shadowLeft,
      'background-color': shadowColor,
      width: shadowWidth,
      height: shadowHeight,
    }"
  ></div>
</div>
