import { effect, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStateService {
  private personalAccessTokenSignal = signal<string | null>(null);
  private passwordSignal = signal<string | null>(null);

  readonly personalAccessToken = this.personalAccessTokenSignal.asReadonly();
  readonly password = this.passwordSignal.asReadonly();

  constructor() {
    effect(() => {
      const personalAccessToken = this.personalAccessTokenSignal();
      if (personalAccessToken !== null) {
        sessionStorage.setItem('personalAccessToken', personalAccessToken);
      }
    });
    effect(() => {
      const password = this.passwordSignal();
      if (password !== null) {
        sessionStorage.setItem('password', password);
      }
    });
  }

  setPersonalAccessToken(value: string) {
    this.personalAccessTokenSignal.set(value);
  }

  setPassword(value: string) {
    this.passwordSignal.set(value);
  }
}
