<div class="checkbox-container">
  <div class="input-container" [class.error]="hasError()" [formGroup]="form">
    <input
      type="checkbox"
      [id]="name"
      [formControlName]="controlName"
      (keydown.enter)="this.input.nativeElement.click()"
      attr.aria-label="{{ altText }}"
      #checkbox
    />
    <label [for]="name" [class.visually-hidden]="!isContentVisible"><ng-content></ng-content></label>
  </div>
  @if (hasError() && isContentVisible) {
    <span class="error-message">{{ getErrorMessage() }}</span>
  }
</div>
