<details (toggle)="onToggle()" #details>
  <summary #summary role="button" [attr.aria-expanded]="details.open">
    <div class="summary-text">
      <span [style.font-weight]="headerFontWeight">{{ header }}</span>
      @if (!details.open) {
        <ng-content select=".preview"></ng-content>
      }
    </div>
    <fa-icon class="fa-icon" [icon]="details.open ? faChevronUp : faChevronDown" title="pfeil"></fa-icon>
  </summary>
  <div>
    <ng-content></ng-content>
  </div>
</details>
