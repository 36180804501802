import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BackendErrorType } from '../../shared/services/errors/backend.errors';
import { MainLayoutComponent } from '../../shared/components/layouts/main-layout/main-layout.component';
import { BannerComponent } from '../../shared/components/banner/banner.component';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { BackendService } from 'app/shared/services/backend.service';
import { lastValueFrom } from 'rxjs';
import { LandingpageConfigurationStateService } from 'app/shared/services/state/landingpage-configuration-state.service';
import { AuthenticationStateService } from 'app/shared/services/state/authentication-state.service';

@Component({
  selector: 'ez-personal-access-token',
  imports: [ReactiveFormsModule, MainLayoutComponent, BannerComponent, ButtonComponent],
  templateUrl: './personal-access-token.component.html',
  styleUrl: './personal-access-token.component.scss',
})
export class PersonalAccessTokenComponent {
  defaultTitle: string;
  form = new FormGroup({
    personalAccessToken: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });
  submitPersonalAccessTokenIsLoading: boolean;

  constructor(
    private backendService: BackendService,
    private landingpageConfigurationStateService: LandingpageConfigurationStateService,
    private authenticationStateService: AuthenticationStateService,
    private router: Router,
    private logger: NGXLogger,
  ) {}

  get tokenInput(): AbstractControl {
    return this.form.controls.personalAccessToken;
  }

  hasError(): boolean {
    const tokenInput = this.tokenInput;
    return tokenInput && tokenInput.invalid && (tokenInput.dirty || tokenInput.touched);
  }

  getErrorMessage(): string {
    const tokenInput = this.tokenInput;
    if (tokenInput.errors?.['required']) {
      return 'Fehler: Dies ist ein Pflichtfeld. Bitte geben Sie Ihren Zugangscode ein.';
    } else if (tokenInput.errors?.[BackendErrorType.CLIENT_ERROR]) {
      return 'Fehler: Bitte überprüfen Sie Ihre Eingabe.';
    } else if (tokenInput.errors?.[BackendErrorType.SERVER_ERROR]) {
      return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
    }
    return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
  }

  onSubmit() {
    const personalAccessToken = this.form.controls['personalAccessToken'].value;

    this.submitPersonalAccessTokenIsLoading = true;
    lastValueFrom(this.backendService.submitPersonalAccessToken(personalAccessToken))
      .then((response) => {
        this.logger.debug('Submit access token response:', {
          requestParam: personalAccessToken,
          response: response,
        });
        this.authenticationStateService.setPersonalAccessToken(personalAccessToken);
        this.landingpageConfigurationStateService.setLandingpageOrganisation(response);
        this.router.navigate(['auth']);
      })
      .catch((error) => {
        if (error.message === BackendErrorType.ALREADY_SENT) {
          this.router.navigate(['already-sent']);
        } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
          this.router.navigate(['verification-error']);
        } else if (error.message === BackendErrorType.SERVER_ERROR) {
          this.router.navigate(['error']);
        } else {
          this.tokenInput.setErrors({ [error.message]: true });
        }
      })
      .finally(() => {
        this.submitPersonalAccessTokenIsLoading = false;
      });
  }
}
