import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { BackendErrorType } from '../../shared/services/errors/backend.errors';
import { BackendService } from 'app/shared/services/backend.service';

@Component({
  selector: 'ez-personal-access-token',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './personal-access-token.component.html',
  styleUrl: './personal-access-token.component.scss',
})
export class PersonalAccessTokenComponent {
  defaultTitle: string;

  personalAccessTokenForm = new FormGroup({
    personalAccessToken: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    private backendService: BackendService,
    private router: Router,
    private logger: NGXLogger,
  ) {}

  get tokenInput(): AbstractControl {
    return this.personalAccessTokenForm.controls.personalAccessToken;
  }

  hasError(): boolean {
    const tokenInput = this.tokenInput;
    return tokenInput && tokenInput.invalid && (tokenInput.dirty || tokenInput.touched);
  }

  getErrorMessage(): string {
    const tokenInput = this.tokenInput;

    if (tokenInput.errors?.['required']) {
      return 'Fehler: Dies ist ein Pflichtfeld. Bitte geben Sie Ihren Zugangscode ein.';
    } else if (tokenInput.errors?.[BackendErrorType.CLIENT_ERROR]) {
      return 'Fehler: Bitte überprüfen Sie Ihre Eingabe.';
    } else if (tokenInput.errors?.[BackendErrorType.SERVER_ERROR]) {
      return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
    }
    return 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
  }

  onSubmit() {
    const personalAccessToken = this.personalAccessTokenForm.controls['personalAccessToken'].value;

    this.backendService.submitPersonalAccessToken(personalAccessToken).subscribe({
      next: (response) => {
        this.logger.debug('Submit access token response:', {
          requestParam: personalAccessToken,
          response: response,
        });

        this.router.navigate(['todo']);
      },
      error: (error: Error) => {
        if (error.message === BackendErrorType.ALREADY_SENT) {
          this.router.navigate(['already-sent']);
        } else if (error.message === BackendErrorType.VERIFICATION_ERROR) {
          this.router.navigate(['verification-error']);
        } else {
          this.tokenInput.setErrors({ [error.message]: true });
        }
      },
    });
  }
}
