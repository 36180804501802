import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { autoUpdate, computePosition } from '@floating-ui/dom';

@Component({
  selector: 'ez-popover',
  imports: [],
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.scss',
})
export class PopoverComponent implements OnDestroy, AfterViewInit {
  @Input() referenceEl: HTMLElement;
  @ViewChild('popover') popoverEl: ElementRef;
  @Output() outsideClick = new EventEmitter();
  @Input() placement: 'top' | 'bottom' = 'bottom';

  constructor(private renderer: Renderer2) {}

  onOutsideClick() {
    this.outsideClick.emit();
  }

  ngAfterViewInit(): void {
    this.stopUpdates = autoUpdate(this.referenceEl, this.popoverEl.nativeElement, this.updatePositionCallback());
    this.outsideClickListener();
  }

  ngOnDestroy(): void {
    this.stopUpdates();
    this.stopListener();
  }

  private stopUpdates: () => void;
  private stopListener: () => void;

  private updatePositionCallback(): () => void {
    return () =>
      computePosition(this.referenceEl, this.popoverEl.nativeElement, { placement: this.placement }).then(
        (position) => {
          Object.assign(this.popoverEl.nativeElement.style, {
            top: `${position.y}px`,
          });
        },
      );
  }

  private outsideClickListener() {
    this.stopListener = this.renderer.listen('window', 'click', (e: Event) => {
      const isOutsidePopover = e.target !== this.popoverEl.nativeElement;
      const isOutsideReference = !(this.referenceEl as Node).contains(e.target as Node);
      if (isOutsidePopover && isOutsideReference) {
        this.onOutsideClick();
      }
    });
  }
}
