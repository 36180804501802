<form [formGroup]="personalAccessTokenForm" (ngSubmit)="onSubmit()">
  <input
    type="password"
    placeholder="Bitte Zugangscode eingeben"
    formControlName="personalAccessToken"
    [class.error]="hasError()"
  />
  @if (hasError()) {
    <span class="error-message">{{ getErrorMessage() }}</span>
  }
  <button type="submit">TODO2</button>
</form>
